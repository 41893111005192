import { render, staticRenderFns } from "./pageElementDropdown.vue?vue&type=template&id=2b35fe72&scoped=true&"
import script from "./pageElementDropdown.vue?vue&type=script&lang=js&"
export * from "./pageElementDropdown.vue?vue&type=script&lang=js&"
import style0 from "./pageElementDropdown.vue?vue&type=style&index=0&id=2b35fe72&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b35fe72",
  null
  
)

export default component.exports