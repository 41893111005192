<template>
  <div>
    <v-select
      :items="item.Options"
      v-model="item.Value"
      :label="$t('Select')"
      single-line
      menu-props="bottom"
      item-value="Id"
      item-text="Value"
      :rules="dropdownRule"
      validate-on-blur
      :clearable="!item.Required"
      :disabled=" $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)"
    ></v-select>
    <template v-if="$store.getters.editMode">
      <hr />
      <h4 class="mt-5">{{ $t("Modify select options") }}</h4>

      <template v-for="(option, optionIndex) in item.Options">
        <v-chip
          :key="optionIndex"
          class="ml-0"
          close
          @click="removeOption(optionIndex)"
        >
          {{ option.Value }}
        </v-chip>
      </template>
      <br />
      <v-row no-gutters class="my-5">
        <v-col cols="12" lg="6">
          <v-text-field
            :label="$t('Option name')"
            v-model="newOptionName"
            required
            :error="addOptionFieldHasError"
            :rules="addOptionFieldRule"
            class="mr-xs-0 mr-sm-3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-btn
            class="
              my-3
              mr-3
              float-left
              edit-page-action-button
              dropdown-action-button
            "
            color="green"
            dark
            @click="addOption"
          >
            {{ $t("Add option") }}
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" lg="12">
          <v-row no-gutters>
            <v-col cols="12">
              <v-layout column>
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  dense
                  :label="$t('Column width')"
                  class="mw-w"
                ></v-text-field>
                <v-switch
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>
                <v-switch
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    }
  },
  data() {
    return {
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      newOptionName: "",
      addOptionFieldHasError: false,
      addOptionFieldRule: [],
    };
  },
  computed: {
    dropdownRule() {
      if (this.item.Required) {
        return [(v) => !!v || this.$i18n.translate("You must select an item")];
      }

      return [];
    },
  },
  methods: {
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    removeOption(index) {
      this.item.Options.splice(index, 1);
    },
    addOption() {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];

      if (this.newOptionName.length > 1) {
        this.item.Options.push({
          Id: this.item.Options.length + 1,
          Value: this.newOptionName,
        });
      } else {
        this.addOptionFieldRule = [
          () => this.$i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
  },
};
</script>

<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>